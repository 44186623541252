<template>
  <div>
    <div class="detail flex" v-for="(item, index) in [list]" :key="index">
      <div class="left">
        <img :src="item && item.productImg" alt="" />
      </div>
      <div class="right">
        <p class="title">{{ item.productName }}</p>
        <p class="content">
          {{ item.productInfo }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pingtaidetail",
  props: ["list"],
  data() {
    return {};
  },
  created() {},
  wacth: {},
  methods: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.detail {
  min-height: 319px;

  .title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 22px;
  }
  .content {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .left {
    // background: #fafafa;
    width: 561px;
    height: 318px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    padding: 47px 27px 0 15px;
    box-sizing: border-box;
  }
}
</style>
